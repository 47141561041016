// --------------------------  vendor  -------------------------- //

import "@stimulus/polyfills";
import "turbolinks-animate";

import $ from "cash-dom";
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import Turbolinks from "turbolinks";

// --------------------------  handle external links  -------------------------- //

$("a")
  .not('not[href*="mailto:"]')
  .each(function externalify() {
    const isInternalLink = new RegExp(`/${window.location.host}/`);
    if (!isInternalLink.test(this.href)) {
      $(this).attr("target", "_blank");
    }
  });

// --------------------------  initialise turbolinks  -------------------------- //

Turbolinks.start();

document.addEventListener("turbolinks:load", () => {
  TurbolinksAnimate.init({
    animation: "fadeIn",
    element: document.querySelector("body"),
    duration: ".25s",
  });
});

/*
  Stimulus
*/

const application = Application.start();
const context = require.context("./controllers", true, /\.js$/);
application.load(definitionsFromContext(context));
