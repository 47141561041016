import $ from "cash-dom";
import Fitvids from "fitvids";
import Flickity from "flickity";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["cells"];

  flickities = [];

  connect() {
    $(this.cellsTargets).each((i, slideshow) => {
      const flickity = new Flickity(slideshow, {
        cellAlign: "left",
        contain: true,
        imagesLoaded: true,
        lazyLoad: 2,
        cellSelector: ".Article__cell",
        wrapAround: true,
      });

      this.flickities.push(flickity);
    });

    // -- apply fitvids to video blocks -- //
    Fitvids(".Article__block--videoBlock");
  }

  disconnect() {
    $(this.flickity).each((i, flickity) => {
      flickity.destroy();
    });
  }
}
