import $ from "cash-dom";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["nav", "toggle"];

  activeClass = "Header__navigation--active";

  toggleActiveClass = "Header__navtoggle--active";

  state = {
    isOpen: false,
  };

  handleEscapeKey = (evt) => {
    let isEscape = false;
    if ("key" in evt) {
      isEscape = evt.key === "Escape" || evt.key === "Esc";
    } else {
      isEscape = evt.keyCode === 27;
    }
    if (isEscape && this.state.isOpen) {
      this.close();
    }
  };

  connect() {
    document.addEventListener("keydown", this.handleEscapeKey);
  }

  disconnect() {
    document.removeEventListener("keydown", this.handleEscapeKey);
  }

  close = () => {
    $(this.navTarget).removeClass(this.activeClass);

    // -- apply active class to the open
    $(this.toggleTarget).removeClass(this.toggleActiveClass);

    // -- set isOpen
    this.state.isOpen = false;
  };

  open = () => {
    // -- apply the active class
    $(this.navTarget).addClass(this.activeClass);

    // -- apply active class to the open
    $(this.toggleTarget).addClass(this.toggleActiveClass);

    // -- set isOpen
    this.state.isOpen = true;
  };

  toggle = (ev) => {
    ev.preventDefault();

    if (this.state.isOpen) {
      this.close();
    } else {
      this.open();
    }
  };
}
