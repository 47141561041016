import Flickity from "flickity";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["cells"];

  flickity = null;

  connect() {
    this.flickity = new Flickity(this.cellsTarget, {
      cellAlign: "center",
      contain: true,
      imagesLoaded: true,
      lazyLoad: 2,
      cellSelector: ".Block",
      wrapAround: false,
      percentPosition: false,
      draggable: ">2",
    });
  }

  disconnect() {
    this.flickity.destroy();
  }
}
