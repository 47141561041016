import $ from "cash-dom";
import inViewport from "in-viewport";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  connect() {
    if (window.innerWidth >= 768) {
      inViewport(this.element, {}, (el) => {
        $(el).addClass("GridLines--inview");
      });
    }
  }
}
