import $ from "cash-dom";
import Flickity from "flickity";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["cells"];

  flickity = null;

  connect() {
    $(this.cellsTarget).each((i, slideshow) => {
      this.flickity = new Flickity(slideshow, {
        cellAlign: "left",
        contain: true,
        imagesLoaded: true,
        lazyLoad: 2,
        cellSelector: ".Slideshow__cell",
        wrapAround: true,
      });
    });
  }

  disconnect() {
    this.flickity.destroy();
  }
}
