import $ from "cash-dom";
import inViewport from "in-viewport";
import { Controller } from "stimulus";

import { addRandomTransitionDelayTo } from "../helpers";

export default class extends Controller {
  static targets = [];

  connect() {
    addRandomTransitionDelayTo(this.element);

    inViewport(this.element, {}, (el) => {
      $(el).addClass("Block--inview");
    });
  }
}
