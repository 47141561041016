import $ from "cash-dom";
import { Controller } from "stimulus";

export default class extends Controller {
  initialize() {}

  connect() {
    setTimeout(() => {
      $(this.element).addClass("Standfirst--ready");
    }, 0);
  }

  disconnect() {
    $(this.element).removeClass("Standfirst--ready");
  }
}
