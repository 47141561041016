import $ from "cash-dom";
import { Controller } from "stimulus";

export default class extends Controller {
  initialize() {}

  connect() {
    setTimeout(() => {
      $(this.element).addClass("Hero--ready");
    }, 0);
  }

  disconnect() {
    $(this.element).removeClass("Hero--ready");
  }
}
