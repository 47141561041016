import Headroom from "headroom.js";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  connect() {
    this.initializeHeadroom();
  }

  initializeHeadroom = () => {
    const headroom = new Headroom(this.element, {
      classes: {
        initial: "visibility",
        pinned: "visibility-pinned",
        unpinned: "visibility-unpinned",
        top: "visibility-top",
        notTop: "visibility-not-top",
        bottom: "visibility-bottom",
        notBottom: "visibility-not-bottom",
      },
    });
    headroom.init();
  };
}
